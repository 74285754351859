import '../css/Subscribe.css';
import gift from '../media/icons/gift.png';
import twitter from '../media/icons/twitter.png';
import illustration from '../media/subscribe/illustration.svg';

import { useState } from 'react';
import { isEmail } from 'validator';

const Subscribe = () => {

  const [header, setHeader] = useState('Subscribe!')
  const [email, setEmail] = useState('');
  const [username, setUsername] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);

  const [resultTitle, setResultTitle] = useState('Aw, Snap!');
  const [resultText, setResultText] = useState('Something went wrong while submitting this form. Please try again later.');

  const validateEmailValue = (event) => {
    let value = event.target.value;
    setEmail(value);
    setIsDisabled(!isEmail(value));
  }

  const validateTwitterValue = (event) => {
    let value = event.target.value;
    setUsername(value);
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    try {
      // Make API request
      let res = await fetch('/v1/forms/subscriptions', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ email, username })
      });

      // Switch messages depending on result
      switch (res.status) {
        case 200: // OK
          setResultTitle('One more thing...');
          setResultText('Please click on the verification link in your email inbox to confirm your subscription to our mailing list.');
          break;
        case 201: // DUPLICATE
          setResultTitle('One more thing...');
          setResultText('Please click on the verification link in your email inbox to update your subscription to our mailing list.');
          break;
        case 400: // ERROR
          setResultTitle('Aw, Snap!');
          setResultText('We could not validate your email or username. Are you sure it is correct?');
          break;
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setHeader('Thanks!');
      setIsHidden(true);
    }
  }

  return <div className="container d-flex flex-column">
    <div className="row align-items-center justify-content-center gx-0 min-vh-100 mb-3">
      <div className="col-12 col-md-12 col-lg-10 col-xl-7 py-8 py-md-11">

        <div className="card">

          <div className="card-header">
            <h3>{header}</h3>
          </div>

          <div className={isHidden ? 'visible' : 'd-none'}>
            <div className="card-body">
              <h5 className="card-title text-center">{resultTitle}</h5>
              <p className="card-text text-center">{resultText}</p>
            </div>
          </div>

          <div className={isHidden ? 'd-none' : undefined}>
            <div className="card-body">
              <h5 className="card-title text-center">Drop your email below to subscribe to our mailing list...</h5>

              <p className="card-text text-center">
                <img src={gift} width="18" height="18" alt="gift icon"></img>
                &nbsp; Include your Twitter handle for a chance to win <strong>Cafeteria Credits</strong> at beta launch!
              </p>

              <div className="illustration text-center">
                <img className="img-fluid" src={illustration} alt="illustration"></img>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <form>

                    <div className="form-group">
                      <input disabled={isLoading} name="email" type="text" className="form-control form-control-lg" id="email" required onChange={validateEmailValue}></input>
                      <label htmlFor="email">Email</label>
                    </div>

                    <div className="form-group">
                      <input disabled={isLoading} name="username" type="text" className="form-control form-control-lg" id="username" required onChange={validateTwitterValue}></input>
                      <label htmlFor="username">
                        <img src={twitter} width="18" height="18" alt="twitter"></img>
                        &nbsp; @
                      </label>
                    </div>

                    <div className="row justify-content-center button-holder g-0 w-100">
                      <button disabled={isDisabled} type="button" className="btn btn-primary btn-lg btn-form" onClick={onSubmit}>
                        <span className={isLoading ? 'spinner-border spinner-border-sm' : 'd-none'} role="status" aria-hidden="true"></span>
                        <span className={isLoading ? 'd-none' : undefined}>Submit</span>
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
};

export default Subscribe;
