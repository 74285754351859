import '../css/Subscribe.css';

import { useState, useEffect } from 'react';

const Verify = () => {

  const [isLoading, setIsLoading] = useState(true);

  const [resultHeader, setResultHeader] = useState('Success!');
  const [resultTitle, setResultTitle] = useState('Your email has been verified');
  const [resultText, setResultText] = useState('Thank you for subscribing to our mailing list! Stay tuned for more updates.');

  const verifyEmail = async () => {
    try {
      // Get token from URL
      const params = new URLSearchParams(window.location.search);
      const token = params.get('token');

      // Make API request
      let res = await fetch('/v1/forms/subscriptions', {
        method: 'PUT',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ token })
      });

      // Switch messages depending on result
      switch(res.status) {
        case 400:
          setResultHeader('Error!');
          setResultTitle('Aw, Snap!');
          setResultText('We could not validate your email as this link may have expired. Please try again!');
          break;          
        default:
          break;
      }
    } catch (err) {
      console.log(err);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => { return verifyEmail() }, []);

  return <div className="container d-flex flex-column">
    <div className="row align-items-center justify-content-center gx-0 min-vh-100">
      <div className="col-12 col-md-12 col-lg-10 col-xl-7 py-8 py-md-11">

        <div className={isLoading ? 'visible' : 'd-none'}>
          <div className="text-center">
            <div className="spinner-border text-primary" role="status">
              <span className="visually-hidden">Loading...</span>
            </div>
          </div>
        </div>
        
        <div className={isLoading ? 'd-none' : 'visible'}>

          <div className="card">

            <div className="card-header">
              <h3>{ resultHeader }</h3>
            </div>
            <div className="card-body">
              <h5 className="card-title text-center">{ resultTitle }</h5>

              <p className="card-text text-center">{ resultText }</p>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div >
};

export default Verify;