import logo from '../media/logo.png';
import '../css/App.css';

import { useEffect } from 'react'

const Index = () => {

  const loadPath = (path) => {
    document.body.classList.add('fade-transition');
    setTimeout(() => {
      window.location.pathname = path;      
    }, 500);
  }

  useEffect(() => {
    const loadMarketingPage = () => {
      let hostname = window.location.hostname;
      if (hostname === 'localhost') {
        let section = window.location.pathname.split('/')[1];
        switch (section) {
          case 'kreechures':
            loadPath('/access');
            break;
          default:
            break;
        }
      } else {
        switch (hostname) {
          case 'kreechures.cafeteria.gg':
            loadPath('/access');
            break;        
          default:
            break;
        }
      }
    };    
    return loadMarketingPage();
  }, []);
  
  return (
    <div className="App">
      <header className="App-header">
        <img src={logo} className="App-logo" alt="logo" />
      </header>
    </div>
  );
};

export default Index;