import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Index from './pages/Index';
import Subscribe from './pages/Subscribe';
import Verify from './pages/Verify';
import Access from './pages/Access';

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Index />}>
          <Route path="*" element={<Index />} />
        </Route>
        <Route path="subscribe" element={<Subscribe />}></Route>
        <Route path="verify" element={<Verify />} />
        <Route path="access" element={<Access />} />
      </Routes>
    </BrowserRouter>
  );
}

export default App;