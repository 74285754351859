import '../css/Subscribe.css';
import '../css/Access.css';

import illustration from '../media/kreechures/award-kreechures.svg';

import { useState } from 'react';
import { isAlphanumeric } from 'validator';

const Access = () => {

  const [header, setHeader] = useState('Beta Access')
  const [wallet, setWallet] = useState('');

  const [isLoading, setIsLoading] = useState(false);
  const [isHidden, setIsHidden] = useState(false);
  const [isDisabled, setIsDisabled] = useState(true);
  const [isError, setIsError] = useState(true);

  const [password, setPassword] = useState(' ');

  const validateWalletAddress = (event) => {
    let value = event.target.value;
    setWallet(value);
    setIsDisabled(!isAlphanumeric(value));
  }

  const onSubmit = async (event) => {
    event.preventDefault();
    setIsLoading(true);
    setIsDisabled(true);
    try {
      // Make API request
      let res = await fetch('/v1/forms/access', {
        method: 'POST',
        headers: {
          'Accept': 'application/json, text/plain, */*',
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ wallet })
      });

      // Switch messages depending on result
      switch (res.status) {
        case 200: // OK
          let json = await res.json();
          setHeader('Access Granted');
          setPassword(json.password);
          setIsError(false);
          break;
        case 400: // ERROR
          setHeader('Access Denied');
          setIsError(true);
          break;
        default:
          setHeader('Access Denied');
          setIsError(true);
          break;
      }
    } catch (err) {
      console.log(err);
      setIsError(true);
    } finally {
      setIsHidden(true);
    }
  }

  return <div className="container d-flex flex-column">
    <div className="row align-items-center justify-content-center gx-0 min-vh-100 mb-3">
      <div className="col-12 col-md-12 col-lg-10 col-xl-7 py-8 py-md-11">

        <div className="card">

          <div className="card-header">
            <h3>{header}</h3>
          </div>

          <div className={isHidden ? 'visible' : 'd-none'}>
            <div className="card-body">
              { /** Error */ }
              <div className={isError ? 'visible' : 'd-none'}>
                <p className="card-text text-center">We could not validate your wallet address. Are you sure it is correct?</p>
              </div>
              <div className={isError ? 'row justify-content-center button-holder g-0 w-100 visible' : 'd-none'}>
                <a href="/access" className="btn btn-primary btn-lg btn-form">
                  Go back
                </a>
              </div>

              { /** Success */}
              <div className={!isError ? 'visible' : 'd-none'}>
                <p className="card-title text-center"><span className="fw-600">The password is: </span><strong>{ password }</strong></p>
                <p className="card-text text-center">To gain early access, tap the button below and enter the password provided above.</p>
              </div>              
              <div className={!isError ? 'row justify-content-center button-holder g-0 w-100 visible' : 'd-none'}>
                <a href="https://cafeteria.gg/#/login" className="btn btn-primary btn-lg btn-form">
                  Access Beta
                </a>
              </div>              
            </div>
          </div>

          <div className={isHidden ? 'd-none' : undefined}>
            <div className="card-body">
              <h5 className="card-title text-center">Drop your Kreechures NFT wallet below to get early BETA access and earn an exclusive Kreechures award!</h5>

              <div className="illustration text-center">
                <img className="col-3 col-md-2 img-fluid" src={illustration} alt="illustration"></img>
              </div>

              <div className="row justify-content-center">
                <div className="col-12 col-md-8">
                  <form onSubmit={onSubmit}>

                    <div className="form-group">
                      <input disabled={isLoading} name="wallet" type="text" className="form-control form-control-lg" id="wallet" required onChange={validateWalletAddress}></input>
                      <label htmlFor="wallet">
                        &nbsp; Wallet Address
                      </label>
                    </div>

                    <div className="row justify-content-center button-holder g-0 w-100">
                      <button disabled={isDisabled} type="button" className="btn btn-primary btn-lg btn-form" onClick={onSubmit}>
                        <span className={isLoading ? 'spinner-border spinner-border-sm' : 'd-none'} role="status" aria-hidden="true"></span>
                        <span className={isLoading ? 'd-none' : undefined}>Submit</span>
                      </button>
                    </div>

                  </form>
                </div>
              </div>
            </div>
          </div>

        </div>

      </div>
    </div>
  </div>
};

export default Access;
